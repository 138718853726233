import React, { useState, useEffect } from 'react';
import {Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Input } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CustomAlert from './Alert';

export default function Locations({token}) {
    const [locations , setLocations] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error , setError] = useState(null);
    const [errorMessage , setErrorMessage] = useState('');
    const [success , setSuccess] = useState(null);
    const[successMessage , setSuccessMessage] = useState('');
    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/locations/?d=y`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then(response => response.json())
        .then(data => {
            setLocations(data);
            setLoading(false);
        }).catch(error => {
            setError(true);
            setErrorMessage(`Failed to fetch data: ${error}`);
        });
    }, [token]);
    const handelChange = (event, index, columnName) => {
        // find the location that is being changed
        const filteredLocation = locations.filter((col, i) => i === index);
        if(filteredLocation.length){
            let data = filteredLocation[0];
            data[columnName] = event.target.value;
            const otherLocations = locations.filter((col, i) => i !== index);
            // change it and insert it back
            otherLocations.splice(index, 0, data);
            setLocations(otherLocations);
        }
    }
    const handelSubmit = (index) => {
        setSuccess(false);
        setError(false);
        const locationBeingEdited = locations.filter((col, i) => i === index);
        const locationName = locationBeingEdited[0].location_name;
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logistics_manager/location/${locationName}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(locations[index])
        }).then(response => response.json())
        .then(data => {
            const otherLocations = locations.filter((col, i) => i !== index);
            otherLocations.splice(index, 0, data);
            setLocations(otherLocations);
            setSuccess(true);
            setSuccessMessage('Location updated successfully');
            setError(false);
        }).catch(error => {
            setError(true);
            setErrorMessage(`Failed to update location: ${error}`);
        });
    }
    return (
            <Grid item xs={12} sm={12} sx={{marginTop:'10px'}}>
                {loading ? <CircularProgress size={24} /> : 
                    <>
                    {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}
                    {success && successMessage ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                        {locations.length > 0 ? locations.map((location, index) => {
                            return (
                                <>
                                    <FormControl key={`${index}-name`} fullWidth variant="standard" sx={{marginTop:'20px'}}>
                                        <InputLabel htmlFor="locationName">{`Location name ${index+1}`}</InputLabel>
                                        <Input id="locationName" aria-describedby="Location name" value={location.location_name} onChange={(event) =>{handelChange(event, index, "location_name")}}/>
                                    </FormControl>
                                    <FormControl key={`${index}-contact-name`} fullWidth variant="standard" sx={{marginTop:'10px'}} >
                                        <InputLabel htmlFor="contactName">Contact name </InputLabel>
                                        <Input id="contactName" aria-describedby="contactName" value={location.contact_name} onChange={(event) =>{handelChange(event, index, "contact_name")}}/>
                                    </FormControl>
                                    <FormControl key={`${index}-contact-mob`} fullWidth variant="standard" sx={{marginTop:'10px'}}>
                                        <InputLabel htmlFor="contactMob">Contact mob </InputLabel>
                                        <Input id="contactMob" aria-describedby="contactMob" value={location.contact_mob} onChange={(event) =>{handelChange(event, index, "contact_mob")}}/>
                                    </FormControl>
                                    <FormControl key={`${index}-contact-email`} fullWidth variant="standard" sx={{marginTop:'10px'}}>
                                        <InputLabel htmlFor="contactEmail">Contact email </InputLabel>
                                        <Input id="contactEmail" aria-describedby="contactEmail" value={location.contact_email} onChange={(event) =>{handelChange(event, index, "contact_email")}}/>
                                    </FormControl>
                                    <Button variant="contained" color="primary" sx={{marginBottom:'20px', marginTop:'5px'}} onClick={()=>{handelSubmit(index)}}>Update</Button>
                                </>
                            )
                        }):null}
                    </>
                }
            </Grid>
    )
}