import React from 'react';
import {Paper, Box, Button, Typography, Menu, Tooltip, MenuItem} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExchangeRates from './ExchangeRates';
import ColumnHeaders from './ColumnHeaders';
import Locations from './Locations';

export default function UserSettings({token}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayState, setDisplayState] = React.useState({
        exchangeRates: true,
        columnHeaders: false,
        locations: false
    });
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{marginTop:'50px'}}>
            <Box sx={{ flexGrow: 1, display:{xs:'block', sm:'block', md:'none', lg:'none'}}}>
                <Tooltip title="Open Settings Menu">
                    <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, marginLeft:'1px'}}
                            onClick={handleMenuOpen}
                        >
                        <SettingsIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={()=>{
                                            handleMenuClose()
                                            setDisplayState({
                                            exchangeRates: true,
                                            columnHeaders: false
                                        })
                            }}>Exchange Rates</MenuItem>
                            <MenuItem onClick={()=>{
                                            handleMenuClose()
                                            setDisplayState({
                                            exchangeRates: false,
                                            columnHeaders: true
                                        })
                            }}>Column Headers</MenuItem>
                             <MenuItem onClick={()=>{
                                            handleMenuClose()
                                            setDisplayState({
                                            exchangeRates: false,
                                            columnHeaders: false,
                                            locations: true
                                        })
                            }}>Locations</MenuItem>
                        </Menu>
                </Tooltip>
            </Box>
            <Box sx={{ display: { xs: 'none', sm:'none', md: 'block', lg:'block' } }}>
                    <Typography variant="h3" sx={{marginLeft:'10px', marginTop:'5px',textDecoration:'underline'}}>Settings</Typography>
                    <Paper>
                        <List sx={{ width: '100%', display:'flex', marginTop:'5px',marginLeft:'10px', flexDirection:{
                                xs:'column',
                                sm:'column',
                                custom:'column',
                                surface:'column',
                                md:'column',
                                lg:'column',
                            }}}>
                            <ListItem>
                                <Button sx={{ cursor:'pointer'}} type="button" onClick={()=>{
                                        setDisplayState({
                                            exchangeRates: true,
                                            columnHeaders: false,
                                            locations: false
                                        });
                                    }}>EXCHANGE RATES </Button>
                            </ListItem>
                            <ListItem>
                                
                                <Button sx={{ cursor:'pointer'}} type="button" onClick={()=>{
                                        setDisplayState({
                                            exchangeRates: false,
                                            columnHeaders: true,
                                            locations: false
                                        });
                                    }}>COLUMN HEADERS
                                </Button>
                                
                            </ListItem>
                            <ListItem>
                                
                                <Button sx={{ cursor:'pointer'}} type="button"  onClick={()=>{
                                        setDisplayState({
                                            exchangeRates: false,
                                            columnHeaders: false,
                                            locations: true
                                        });
                                    }}>
                                    LOCATIONS
                                </Button>
                                
                            </ListItem>
                        </List>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} sx={{marginTop:'50px', marginBottom:'50px'}}>
                {displayState.exchangeRates?<ExchangeRates token={token}/>:null}
                {displayState.columnHeaders?<ColumnHeaders token={token}/>:null}
                {displayState.locations?<Locations token={token}/>:null}
            </Grid>
        </Grid>
    );
}